.worker-message {
  background-color: var(--base-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5%;

  img {
    width: 30%;
  }

  div {
    width: 70%;
    position: relative;

    article {
      position: absolute;
      width: 80%;
      top: 50%;
      transform: translateY(-50%);
      left: 7%;

      h2 {
        margin: 3% 0;
      }
    }

    img {
      width: 100%;
    }
  }
}

@media (max-width: 750px) {
  .worker-message {
    flex-direction: column-reverse;

    img {
      width: 100%;
    }

    div {
      width: 100%;
    }
  }
}

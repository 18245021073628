.about-us {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5%;

  img {
    width: 40%;
  }

  article {
    text-align: left;
    width: 50%;
    padding-left: 5%;
  }
}

@media (max-width: 750px) {
  .about-us {
    flex-direction: column;

    img {
      width: 100%;
    }

    article {
      width: 100%;
      padding-left: 0;
    }
  }
}

.printfoots-way {
  text-align: center;
  padding: 5%;

  img {
    width: 50%;
  }
}

@media (max-width: 750px) {
  .printfoots-way {
    img {
      width: 100%;
    }
  }
}

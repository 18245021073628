.form-screen {
  display: flex;
  padding: 5%;
  background-color: var(--base-color);

  .img-ok-container {
    width: 40%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    transition: transform 1s;

    img {
      width: 30%;
    }

    .text-img-ok {
      text-align: center;

      a {
        margin-left: 0.6vw;
        color: white;
        text-decoration: none;
      }
    }
  }

  article {
    width: 60%;
    padding-right: 5%;

    p {
      color: white;
    }

    .workers-container {
      display: flex;
      align-items: flex-start;

      img {
        width: 30%;
      }
    }
  }

  form {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: transform 1s, width 1s;

    span {
      margin: 0;
      color: #a63a00;
      margin-left: 1vw;
      margin-top: -1vw;
      font-size: 1vw;
    }

    .bad-input {
      box-shadow: 0 0 1vw #ff5900;
    }

    input {
      width: 100%;
    }
  }
}

@media (max-width: 1600px) {
  .form-screen {
    form {
      span {
        margin-left: 20px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 750px) {
  .form-screen {
    flex-direction: column;

    .img-ok-container {
      width: 100%;
      margin-top: 5%;

      .text-img-ok {
        a {
          margin-left: 5px;
        }
      }
    }

    article {
      width: 100%;
      padding-right: 0;

      .workers-container {
        justify-content: center;
      }
    }

    form {
      width: 100%;
      flex-direction: column;

      .bad-input {
        box-shadow: 0 0 10px red;
      }

      * {
        margin: 10px 0;
      }

      span {
        margin-left: 18px;
      }
    }
  }
}

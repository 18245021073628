@mixin city {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
}

@mixin wheel {
  position: absolute;
  width: 18%;
  bottom: 5px;
  z-index: 3;
  transform: rotate(0);
}

.welcome-section {
  height: 90vh;
  min-height: 500px;
  position: relative;

  header {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 3;
    padding: 2%;
    width: 100%;
    z-index: 5;

    #logo {
      width: 12vw;
    }

    .position-container {
      width: 100%;
      text-align: right;

      a {
        font-size: 1vw;
        color: var(--base-color);
        text-decoration: none;
        font-weight: bold;

        img {
          width: 3vw;
        }
      }
    }
  }

  #city1, {
    @include city;

    transform: translate(0);
  }

  #city2, {
    @include city;

    transform: translate(100%);
  }

  .car-container {
    position: absolute;
    bottom: 0;
    left: 20px;
    width: 30vw;
    z-index: 2;
  }

  #wheel1 {
    @include wheel;

    left: 15%;
  }

  #wheel2 {
    @include wheel;

    left: 75%;
  }

  article {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 90vh;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      width: 20%;
    }
  }
}

@media (max-width: 1600px) {
  .welcome-section {
    header {
      #logo {
        width: 190px;
      }

      .position-container {
        a {
          font-size: 15px;

          img {
            width: 50px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .welcome-section {
    article {
      h1 {
        font-size: 13vw;
      }
    }
  }
}

@media (max-width: 400px) {
  .welcome-section {
    header {
      #logo {
        width: 40vw;
      }
      .position-container {
        a {
          font-size: 4vw;

          img {
            width: 10vw;
          }
        }
      }
    }
  }
}

@import "variables";

$btn_color: #1388a8;
$title_color: #140c00;
$text_color: #7a4900;

/* ScrollBar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: $btn_color;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($btn_color, 15%);
}

body,
#root {
  overflow-x: hidden;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Nunito', sans-serif;
}

p {
  color: $text_color;
  margin: 0.7vw 0;
}

h1,
h2,
h3,
h4,
h5 {
  color: $title_color;
  font-weight: bold;
}

h1 {
  font-size: 5.5vw;
}

h2 {
  font-size: 2vw;
}

h3 {
  font-size: 1.5vw;
}

h4 {
  font-size: 0.7vw;
}

button,
p,
form input {
  font-size: 1.2vw;
}

form input,
button {
  border-radius: 2vw;
  border: none;
  padding: 0.4vw 1vw;
}

button {
  background-color: $btn_color;
  color: white;
  width: 30%;
  min-width: 150px;
  cursor: pointer;

  &:hover {
    background: darken($btn_color, 15%);
  }
}

@media (max-width: 1600px) {
  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 11px;
  }

  button,
  p,
  form input {
    font-size: 18px;
  }

  form input,
  button {
    border-radius: 20px;
    padding: 5px 20px;
  }

  p {
    margin: 10px 0;
  }
}

@media (max-width: 750px) {
  h2 {
    font-size: 5vw;
  }
}

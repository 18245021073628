.footer {
  display: flex;
  flex-direction: column;
  text-align: center;

  div {
    background-color: var(--dark-color);
    padding: 1%;

    h4 {
      color: white;
      font-weight: normal;

      a {
        text-decoration: none;
        color: white;
        margin-left: 0.2vw;
      }
    }
  }

  section {
    padding: 1% 0 0 0;

    article {
      margin: 1% 0;
      font-size: 0.9vw;

      h4 {
        font-weight: normal;
      }

      a {
        text-decoration: none;
        color: black;
        position: relative;
        padding-left: 1.1vw;

        img {
          position: absolute;
          top: -0.3vw;
          left: -5px;
          width: 1.2vw;
        }
      }

      .email {
        padding-left: 0;
        color: var(--base-color);
      }

      span {
        display: block;
      }
    }
  }
}

@media (max-width: 1600px) {
  .footer {
    section {
      article {
        font-size: 14px;

        a {
          padding-left: 14px;

          img {
            top: -5px;
            left: -5px;
            width: 20px;
          }
        }
      }
    }

    div {
      h4 {
        a {
          margin-left: 3px;
        }
      }
    }
  }
}

.flex-normal {
  flex-direction: row;
}

.flex-reversed {
  flex-direction: row-reverse;
}

.image-list {
  display: flex;
  align-items: center;
  padding: 5%;

  article {
    width: 50%;
    text-align: center;

    div {
      display: inline-block;
      text-align: left;

      h3 {
        span {
          color: var(--base-color);
          margin-left: 0.6vw;
        }
      }

      ul {
        list-style: none;
        margin-top: 20px;

        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 2vw;
          }
        }
      }
    }
  }

  .image-container {
    width: 50%;
    text-align: center;

    img {
      width: 90%;
    }
  }
}

@media (max-width: 1600px) {
  .image-list {
    article {
      div {
        h3 {
          span {
            margin-left: 6px;
          }
        }

        ul {
          li {
            img {
              width: 35px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .image-list {
    flex-direction: column;

    .image-container {
      width: 100%;
    }

    article {
      width: 100%;
      text-align: left;
    }
  }
}
